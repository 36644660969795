import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import 'tachyons/css/tachyons.min.css'

import Layout from '../components/Layout'

const Wrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700');

  background: url(${props => props.backgroundImage}) no-repeat 150px 0;
  background-size: auto;
  height: 100%;
  font-family: 'PT Sans Caption', sans-serif;
  
`

const Form = styled.form.attrs({
  className: 'w-80 mt4 flex-column flex justify-center items-center',
  method: 'POST'
})`
`

const Input = styled.input.attrs({
  className: 'w-100 mv2',
  id: 'email',
  type: 'email',
  name: '_replyto',
  placeholder: 'Your Email Address'
})`
  padding: 0.5rem;
  text-align: center;
`

const Button = styled.button.attrs({
  className: 'w-100 tc',
  type: 'submit'
})`
  font-weight: bold;
  text-decoration: none;
  color: white;
  padding: 0.5rem 0;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: none;
`

const Shape = styled.div`
  position: fixed;
  background-color: #ecececde;
  height: 100%;
  width: 140%;
  top: -120px;
  left: 0;
  transform: rotateZ(120deg);
`

const Relative = styled.div`
  position: relative;
`

const Text1 = styled.span`
  color: ${props => props.color};
`

const Header = styled.div.attrs({
  className: 'flex justify-center items-center bg-black-30 pv2'
})`
  width: 100%;
  height: 120px;
  padding: 1rem;
`

const Logo = styled.img.attrs({
  alt: 'logo'
})`
  max-width: 100%;
  max-height: 100%;
`

const EmailForm = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={frontmatter}>
    <Wrapper primaryColor={frontmatter.primaryColor} backgroundImage={frontmatter.backgroundImage.publicURL}>
      <main className='pb5'>

        <Shape />

        <Relative>
          <Header>
            <Logo src={frontmatter.logo.publicURL} />
          </Header>

          <section className='ph5 tc'>
            <h1 className='f1 ttu tc b tracked'>{frontmatter.header}</h1>
            <h2 className='f3 lh-copy tc mb4 tracked'>
              <Text1 color={frontmatter.primaryColor}>{frontmatter.text1}</Text1>
              &nbsp;
              <span>{frontmatter.text2}</span>
            </h2>
            <div className='f6 lh-copy tc'>{frontmatter.text3}</div>
          </section>

          <section className='w-100 flex-column flex justify-center items-center'>
            <Form action={`https://formspree.io/${frontmatter.emailAddress}`}>
              <Input />
              <Button color={frontmatter.primaryColor}>Send</Button>
            </Form>
          </section>

        </Relative>

      </main>
    </Wrapper>
  </Layout>

export default EmailForm

export const pageQuery = graphql`
  query EmailFormBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        description
        title
        header
        text1
        text2
        text3
        emailAddress
        logo {
          publicURL
        }
        backgroundImage {
          publicURL
        }
        backgroundColor
        primaryColor
        favicon {
          publicURL
        }
      }
    }
  }
`
